import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../../domain/services/configService';
import { theme } from '../../../domain/theme';
import { stripTags, truncateText } from '../../../domain/utils/stringUtils';
import { Actionable } from '../atoms/Actionable';

export const ReadMore = ({ text, maxChars }) => {
  const [isOpen, setIsOpen] = useState(false);
  const truncatedText = truncateText(text, maxChars);
  const canBeTruncated = stripTags(text).length !== truncatedText.length;
  const textToShow = isOpen ? text : truncatedText;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const styles = {
    text: css`
      ${theme.font.family('boldWeb')};
      border-bottom: 1px solid #fc0;
      padding-bottom: 3px;
    `,
    copyText: css`
      a {
        color: #fc0;
        text-decoration: underline;
      }
    `
  };

  const actionableTitle = t(isOpen ? 'Show less' : 'Read More');

  return (
    <p>
      <span
        css={styles.copyText}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: textToShow }}
      />
      {canBeTruncated && (
        <>
          {' '}
          <Actionable
            title={actionableTitle}
            onClick={toggleOpen}
            display="inline-block"
          >
            <span css={styles.text}>{actionableTitle}</span>
          </Actionable>
        </>
      )}
    </p>
  );
};

ReadMore.defaultProps = {
  maxChars: undefined
};

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  maxChars: PropTypes.number
};
